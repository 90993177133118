@import '../style.scss';

.connect-wallet-modal {
  padding-bottom: 20px;

  & .modal {
    max-height: 400px;
    overflow: auto;
  }

  &__card {
    margin: 0 20px 20px 20px;
    padding: 20px;
    border-radius: 14px;
    background-color: rgba(214, 216, 255, 0.05);
    font-size: 16px;
    & a {
      margin: 0 5px;
      color: $color-blue;
    }
  }

  &__wallet {
    display: flex;
    justify-content: space-between;
    margin: 20px;
    padding: 20px;
    border-radius: 14px;
    background-color: rgba($color-grey-dark, 0.6);
    font-size: 18px;
    cursor: pointer;
    border: 1px solid rgba(214, 216, 255, 0.25);
    user-select: none;

    &:hover {
      border: 1px solid $color-blue;
    }

    &--icon {
      height: 25px;
      width: 25px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      &.metamask {
        background-image: url('../images/metamask.png');
      }
    }

    &--title {
      height: 28px;
      line-height: 28px;
    }
  }

  &__info {
    display: flex;
    margin: 20px;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    border-radius: 14px;
    background-color: rgba(214, 216, 255, 0.25);
    border: 1px solid rgba(214, 216, 255, 0.25);
    &--content {
      margin-top: 2px;
      font-size: 14px;
    }
  }

  &__init {
    display: flex;
    margin: 20px;
    padding: 20px;
    justify-content: flex-start;
    border-radius: 14px;
    border: 1px solid rgba(214, 216, 255, 0.25);
    & .spinner {
      margin-right: 10px;
    }
  }

  &__error {
    display: flex;
    margin: 20px;
    padding: 20px;
    justify-content: flex-start;
    border-radius: 14px;
    border: 1px solid $color-red;
    color: $color-red;
    & .button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 5px;
      margin-left: 10px;
      font-size: 14px;
      border-radius: 12px;
      background-color: $color-grey-dark;
      color: $color-white;
      cursor: pointer;
    }
  }
}
