@import "../style.scss";

.dashboard-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
}
.dashboard {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 640px;
  padding-bottom: 100px;

  &__header {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 64px;
  }
  &__title {
    font-size: 46px;
    align-self: center;
    font-weight: $weight-bold;
  }

  &__subtitle {
    font-size: 14px;
    color: $color-font-light;
    max-width: 366px;
    align-self: center;
    text-align: center;
  }

  &__submit {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &--button {
      background-color: $color-green;
      height: 52px;
      width: 184px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 36px;
      border-radius: 6px;
      align-self: center;
      cursor: pointer;
      font-weight: $weight-semi;

      &.disabled {
        background: #373c4f 0% 0% no-repeat padding-box;
        color: #8a8fb5;
        cursor: not-allowed;
      }

      & .spinner {
        margin-right: 10px;
      }
    }

    &--error {
      color: $color-red;
      margin-top: 20px;
      margin-bottom: -10px;
    }
  }

  &__download {
    color: $color-font-light;
    font-size: 12px;
    letter-spacing: 0;
    margin-top: 38px;

    &--title {
      text-align: center;
      letter-spacing: 3px;
      margin-bottom: 28px;
    }

    &--link {
      color: $color-green;
      text-align: center;
    }
  }
}
.theta-logo {
  height: 68px;
  width: 42px;

  background-image: url("../images/theta-logo@2x.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}
