@import '../style.scss';

.account-modal {
  & .modal {
    max-height: 400px;
    overflow: auto;
  }

  &__card {
    margin: 0 20px 20px 20px;
    padding: 20px 20px 15px 20px;
    border: 1px solid $color-font-light;
    border-radius: 14px;

    &--title {
      color: $color-font-light;
    }

    &--address {
      margin-top: 15px;
      font-size: 24px;
    }

    &--links {
      margin-top: 15px;
      display: flex;
      color: $color-font-light;
      justify-content: flex-start;
      align-items: center;

      & a {
        color: $color-font-light;
      }
    }
  }

  &__transactions {
    background-color: rgba($color-grey-dark, 0.6);
    padding: 20px;
  }
}

.card-link {
  margin-right: 20px;
  cursor: pointer;
  min-width: 130px;
  display: flex;

  &:before {
    content: '';
    height: 20px;
    width: 20px;
    top: 2px;
    display: inline-block;
    position: relative;
    margin-right: 3px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &.copy:before {
    background-image: url('../images/copy@2x.png');
  }

  &.view:before {
    background-image: url('../images/view@2x.png');
  }

  &.view:hover::before {
    background-image: url('../images/view-active@2x.png');
  }

  &.copied:before {
    background-image: url('../images/copied@2x.png');
  }

}
