@import "../style.scss";

.theta-tabs {
  margin-top: 50px;
  width: 75%;
  align-self: center;

  .react-tabs {
    &__tab {
      background-color: transparent;
      color: $color-font-light;
      letter-spacing: 1px;
      font-size: 12px;
      font-weight: $weight-bold;
      padding-bottom: 14px;

      &--disabled {
        cursor: not-allowed;
      }
      &:focus::after {
        height: 0px;
      }
    }

    &__tab-list {
      border-bottom: none;
      user-select: none;
      display: flex;
      justify-content: space-evenly;
    }

    &__tab--selected {
      background-color: transparent;
      color: $color-white;
      border-color: transparent;
      border-bottom: 1px solid $color-green;
    }
  }
}

.uploader {
  width: 480px;
  min-height: 165px;
  background: rgba($color-panel, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  &__selector {
    background-color: $color-grey-light;
    height: 36px;
    width: 158px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 42px;
    border-radius: 6px;
    align-self: center;
    cursor: pointer;
    font-weight: $weight-semi;

    & input[type="file"] {
      display: none;
    }
  }

  &__preview {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: $weight-semi;
    color: $color-font-light;
    padding: 0 30px;

    &--empty {
      margin-top: 35px;
    }
  }

  &__file-wrap {
    margin: 23px 10px 40px 10px;
    padding-top: 6px;
    border-top: 1px solid #34384d;
    width: 100%;
  }

  &__file {
    margin-top: 11px;
    display: flex;

    &--icon {
      height: 38px;
      width: 38px;

      background-image: url("../images/file-default@2x.png");
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &--name {
      margin-left: 13px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}

.uploaded {
  width: 640px;
  min-height: 165px;
  background: rgba($color-panel, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  margin-left: -80px;

  &__link {
    font-size: 16px;
    color: $color-green;
    text-align: center;
    margin: 10px 0;

    &:visited {
      color: $color-green;
    }
  }
}
