@import './style.scss';

#root {
  height: 100%;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
    pointer-events: none;
    z-index: -1;
  }
}

#app-container {
  height: 100%;
  width: 100%;
  // @include breakpoint('tablet', max) {
  //   min-width: 100%;
  // }
  &:before {
    content: '';
    position: absolute;
    top: calc(63px - 180px);
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: -1;
  }
}

#app-content {
  height: calc(100% - 60px);
  overflow: auto;

  .content {
    width: 90%;
    max-width: 1100px;
    margin: 0 auto 10rem;

    @include breakpoint('tablet', max) {
      width: 100%;
    }
  }

  @include breakpoint('phablet', max) {
    .content .page-title {
      margin-top: 3rem;
      margin-bottom: 1.5rem;
    }
  }
}
