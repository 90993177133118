@import '../style.scss';

.error-modal {
  & .modal {
    padding: 0 20px 20px 20px;
  }
}

.model-network-err {
  &__button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba($color-blue, 0.5);
    border-radius: 15px;
    height: 40px;
    margin: 20px 0 20px 0;
    color: $color-blue;
    &:hover {
      border-color: $color-blue;
    }
  }
}
