@import '../style.scss';

.nav-bar {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &__logo {
    height: 40px;
    width: 120px;
    display: flex;
    margin-left: 20px;

    & .theta-logo {
      flex: 0 0 7.25rem;
      height: 40px;

      background-image: url('../images/nav-logo.png');
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &__navs {
    display: flex;
    align-items: center;
    cursor: default;
    margin-right: 20px;

    &--network {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      height: 40px;
      background-color: rgba(138, 143, 181, 0.12);
      padding: 10px;
      margin-right: 10px;
      text-transform:capitalize;

      &::before {
        content: '';
        display: inline-block;
        position: relative;
        width: 15px;
        height: 15px;
        margin-right: 0.375rem;
        top: -2px;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../images/favicon.png');
      }
    }

    &--account {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      height: 40px;
      background-color: rgba(138, 143, 181, 0.12);
      // padding: 5px 1px 5px 10px;
    }

    &--ellipsis {
      border-radius: 16px;
      height: 40px;
      width: 42px;
      background-color: rgba(138, 143, 181, 0.12);
      border: 1px solid transparent;
      cursor: pointer;
      margin: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      & svg {
        height: 30px;
        width: 40px;
        transform: rotate(90deg);
      }

      &:hover {
        border: 1px solid rgba(138, 143, 181, 0.8);
      }
    }
  }
}

.account {
  &__balance {
    margin: 0 5px 0 10px;
  }

  &__info {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    margin: 3px;
    border-radius: 14px;
    background-color: rgba(214, 216, 255, 0.15);
    border: 1px solid transparent;
    padding: 0 10px;
    cursor: pointer;

    &:hover {
      border: 1px solid rgba(138, 143, 181, 0.8);
    }
  }

  &__connect {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    background-color: rgba($color-blue, 0.5);
    color: $color-blue;
    font-weight: 600;
    height: 34px;
    padding: 0 10px;
    margin: 5px 3px;
    cursor: pointer;
    user-select: none;
  }

  &__error {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    background-color: rgba($color-red, 0.5);
    color: $color-red;
    font-weight: 600;
    height: 34px;
    padding: 0 10px;
    margin: 5px 3px;
    cursor: pointer;
    user-select: none;
  }
}

.drop-down {
  position: absolute;
  z-index: 100;
  right: 10px;
  top: 50px;
  width: 200px;
  background-color: rgba(214, 216, 255, 0.15);
  border-radius: 12px;
  padding: 8px;
  &__row {
    padding: 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    user-select: none;
    &:hover {
      color: $color-blue;
    }

    &--title {
      display: flex;
      align-items: center;
    }

    &--icon {
      height: 24px;
      width: 24px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      pointer-events: none;
      &.discord {
        background-image: url('../images/discord@2x.png');
      }
    }
  }
}
