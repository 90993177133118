@import '../style.scss';

.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(28, 36, 59, 0.75);
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
  font-size: 18px;
  font-weight: 400;

  .modal-close {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 100;
    width: 45px;
    height: 45px;
    background-image: url('../images/close_button@2x.png');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    // background-color: rgba($color-white, 0.05);
    border: 1px solid transparent;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: rgba(214, 216, 255, 0.05);
    }
  }
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #242f4b;
    width: 500px;
    overflow-y: auto;
    border-radius: 12px;
    cursor: default;
    // box-sizing: border-box;
  }
}

.modal-title {
  padding: 20px;
}

.modal-hr {
  height: 1px;
  background-color: $color-font-light;
}

.modal {
}
