$breakpoints: (
  "phone-small": 350px,
  "phone": 400px,
  "phone-wide": 480px,
  "phablet": 560px,
  "tablet-small": 640px,
  "tablet": 768px,
  "tablet-wide": 1024px,
  "desktop": 1248px,
  "desktop-wide": 1440px,
  //Custom
  "max-width": 1100px,
);

@mixin breakpoint($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

// brand colors
$color-theta-green: #21edba;
$color-theta-blue: #29b3eb;
$color-theta-blue-light: #25c4e4;

// basic colors
$color-white: #fff;
$color-black: #1b1f2a;
$color-blue: $color-theta-blue;
$color-green: $color-theta-green;
$color-light: #7c81a3;
$color-grey-light: #5a6285;
$color-grey-dark: #4a5776;
$color-red: #ff4f0f;
$color-orange: #ffb515;

// panels
$color-page: #1b1f2a;
$color-panel: #373c4f;

// Fonts
$color-font-main: #ffffff;
$color-font-light: #8a8fb5;
$color-font-highlight: $color-theta-blue;

$color-font-title: $color-font-main;
$color-font-body: $color-font-main;
$color-font-link: $color-font-main;
$color-font-link-hover: $color-font-highlight;
$color-font-button: $color-black;
$color-font-button-light: $color-font-main;

// Lines
$color-line: darken($color-light, 25%);
$color-line-main: $color-line;
$color-line-light: darken($color-light, 35%);

// Text select
$color-select-back: $color-font-highlight;
$color-select-type: $color-font-main;

// Forms
$color-input-background: rgba($color-light, 0.35);
$color-input-text: $color-font-main;
$color-input-placeholder: rgba($color-font-main, 0.3);
$color-input-border: $color-line-main;

// Tables
$color-table-row: rgba(#d6d8ff, 0.05);
$color-table-row-alt: rgba(#d6d8ff, 0.02);
$color-table-row-line: rgba(#d6d8ff, 0.12);

// Buttons
$color-button-light: rgba($color-light, 0.45);

//Gradient helpers
@mixin linearVerticalGradient($top, $bottom) {
  background: $top; /* Old browsers */
  background: -moz-linear-gradient(top, $top 0%, $bottom 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, $top),
    color-stop(100%, $bottom)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    $top 0%,
    $bottom 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, $top 0%, $bottom 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, $top 0%, $bottom 100%); /* IE10+ */
  background: linear-gradient(to bottom, $top 0%, $bottom 100%); /* W3C */
}

@mixin linearHorizontalGradient($left, $right) {
  background: $left; /* Old browsers */
  background: -moz-linear-gradient(left, $left 0%, $right 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    $left 0%,
    $right 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    $left 0%,
    $right 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@mixin buttonGradient() {
  @include linearHorizontalGradient($color-theta-blue, $color-theta-green);
}

// Font weights
$weight-extra-light: 200;
$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-semi: 600;
$weight-bold: 700;

// Base Font
$font-title: "Gilroy", "Helvetica", sans-serif;
$font-subtitle: "Gilroy", "Helvetica", sans-serif;
$font-body: "Gilroy", "Helvetica", sans-serif;
$line-height: 1.4;

@font-face {
  font-family: "Gilroy";
  src: url("./fonts/Gilroy-Bold.otf") format("otf"),
    url("./fonts/Gilroy-Bold.woff") format("woff");
  font-weight: $weight-bold;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("./fonts/Gilroy-Semibold.otf") format("otf"),
    url("./fonts/Gilroy-SemiBold.woff") format("woff");
  font-weight: $weight-semi;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("./fonts/Gilroy-Regular.otf") format("otf"),
    url("./fonts/Gilroy-Regular.woff") format("woff");
  font-weight: $weight-regular;
  font-style: normal;
}
